import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMobileMixin from 'StudentNetwork/react/useIsMobileMixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';
import { useCurrentUser, useNavigationHelper } from 'FrontRoyalAngular';
import { getProgramApplication } from 'Users';
import { useSyncConfig } from 'FrontRoyalConfig';
import { targetBrandConfig } from 'AppBranding';

export function ScheduleInterviewBox({ preRecorded }: { preRecorded: boolean }) {
    return <ScheduleInterviewPropsWrapper preRecorded={preRecorded} />;
}

function ScheduleInterviewPropsWrapper({ preRecorded }: { preRecorded: boolean }) {
    const { xsOrSm } = useIsMobileMixin();
    const { loadUrl } = useNavigationHelper();
    const currentUser = useCurrentUser();
    const appConfig = useSyncConfig();
    const interviewLink = useMemo(() => getProgramApplication(currentUser!)?.invitedToInterviewUrl, [currentUser]);
    const brandConfig = targetBrandConfig(currentUser);

    const handleButtonClick = useCallback(() => {
        if (preRecorded) {
            loadUrl(appConfig.preRecordedInterviewUrls()[brandConfig.branding], '_blank');
            return;
        }
        loadUrl(interviewLink, '_blank');
    }, [preRecorded, loadUrl, appConfig, interviewLink, brandConfig.branding]);

    return (
        <ScheduleInterviewPresentation xsOrSm={xsOrSm} preRecorded={preRecorded} onButtonClick={handleButtonClick} />
    );
}

export function ScheduleInterviewPresentation({
    xsOrSm,
    onButtonClick,
    preRecorded,
}: {
    xsOrSm: boolean;
    onButtonClick: () => void;
    preRecorded: boolean;
}) {
    const { t } = useTranslation('back_royal');

    const buttonLocaleKey = preRecorded
        ? 'lessons.stream.student_dashboard_schedule_interview.conduct_interview'
        : 'lessons.stream.student_dashboard_schedule_interview.schedule_interview';

    const desktopHeaderIntroLocaleKey = preRecorded
        ? 'lessons.stream.student_dashboard_schedule_interview.conduct_your'
        : 'lessons.stream.student_dashboard_schedule_interview.schedule_your';

    if (xsOrSm) {
        // MOBILE view
        return (
            <div data-testid="content-body" className="p-5">
                <p className="mb-[30px] text-sm font-normal text-white">
                    {t('lessons.stream.student_dashboard_schedule_interview.congratulations')}
                </p>
                <button
                    className="mb-[10px] flex w-full items-center justify-center rounded-[50px] border border-solid border-white bg-transparent px-[30px] py-[10px] text-base font-medium text-white shadow-none"
                    type="button"
                    onClick={onButtonClick}
                >
                    {t(buttonLocaleKey)}
                </button>
            </div>
        );
    }

    // DESKTOP view
    return (
        <div
            data-testid="schedule-interview-box"
            className="bg-[linear-gradient(162deg,_#2266d0_0%,_#0c2c63_96%)] px-5 py-[30px]"
        >
            <h2 className="flex flex-col items-center justify-center">
                <span className="mb-[5px] block text-xs font-normal uppercase text-white">
                    {t(desktopHeaderIntroLocaleKey)}
                </span>
                <span className="mb-[10px] block text-[28px] font-semibold uppercase text-white">
                    {t('lessons.stream.student_dashboard_schedule_interview.interview')}
                </span>
                <span className="mb-5 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[#3e89fd] text-lg font-normal text-white">
                    <FontAwesomeIcon icon={faCalendar} />
                </span>
            </h2>
            <p className="mb-5 text-sm font-normal text-white">
                {t('lessons.stream.student_dashboard_schedule_interview.congratulations')}
            </p>
            <hr className="mx-auto mb-5 w-[52px] bg-white opacity-50" />
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={onButtonClick}
                    className="m-0 flex h-auto items-center justify-center whitespace-nowrap rounded-[50px] border border-white bg-transparent px-[30px] py-[10px] text-base font-medium normal-case leading-[1.33] tracking-[0.25px] text-white shadow-none transition-all hover:bg-white hover:text-blue-dark"
                >
                    {t(buttonLocaleKey)}
                </button>
            </div>
        </div>
    );
}
