import { faPlane } from '@fortawesome/pro-solid-svg-icons/faPlane';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import useIsMobileMixin from 'StudentNetwork/react/useIsMobileMixin';

export function OfflineBox() {
    return <OfflineBoxPropsWrapper />;
}

function OfflineBoxPropsWrapper() {
    const { xsOrSm } = useIsMobileMixin();
    return <OfflineBoxPresentation xsOrSm={xsOrSm} />;
}

export function OfflineBoxPresentation({ xsOrSm }: { xsOrSm: boolean }) {
    const { t } = useTranslation('back_royal');

    if (xsOrSm) return null;

    // DESKTOP VIEW
    return (
        <div data-testid="content-container" className="bg-grey-dark-blue px-5 py-[30px]">
            <h2 className="mb-5 flex flex-col items-center justify-center">
                <span className="mb-[5px] block text-xs font-normal uppercase text-white">
                    {t('lessons.stream.student_dashboard_offline_box.you_are')}
                </span>
                <span className="mb-[15px] block text-[28px] font-semibold uppercase text-white">
                    {t('lessons.stream.student_dashboard_offline_box.offline')}
                </span>
                <span className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-orange text-lg font-normal text-white">
                    <FontAwesomeIcon icon={faPlane} />
                </span>
            </h2>
            <p data-testid="sub-text" className="mb-0 text-center text-sm font-normal text-white">
                {t('lessons.stream.student_dashboard_offline_box.keep_going')}
            </p>
        </div>
    );
}
