import { type TranslationHelperClass } from 'Translation';
import { type DialogModal } from 'DialogModal';
import selectItemAtRandom from 'Utils/selectItemAtRandom';
import { type AdmissionsModalConfig } from './AdmissionsProcessModals.types';

function getContent(localeNamespace: string, elementConfigs: Awaited<ReturnType<AdmissionsModalConfig['elements']>>) {
    const elements = elementConfigs.map(elementOrElements =>
        // The entries in the list of elements can be either a single element or an array of elements.
        // If it's an array of elements, then we randomly pick one to show each time
        Array.isArray(elementOrElements) ? selectItemAtRandom(elementOrElements) : elementOrElements,
    );

    const imageRow = elements
        .map(
            element =>
                `<div class="icon-item"><img class="${element.imageClassName || ''}" src="${element.icon}"></div>`,
        )
        .join('');

    const textRow = elements
        .map(element => {
            const textRowLocaleParams = Object.keys(element.localeParams ?? {}).reduce(
                (acc: string, key: string) => `${acc}${key}: '${element.localeParams?.[key] ?? ''}', `,
                '',
            );
            return `<div class="text-item"><p translate-once="${localeNamespace}.${element.localeKey}" translate-values="{${textRowLocaleParams}}" translate-compile></p></div>`;
        })
        .join('');

    return (
        `<div class="admissions-process-modal-wrapper">` +
        `<div class="flex-responsive">${imageRow}</div><div class="flex-responsive">${textRow}</div>` +
        `</div>`
    );
}

export default async function showAdmissionsProcessModal(
    injector: ng.auto.IInjectorService,
    modalConfig: AdmissionsModalConfig,
) {
    const DialogModal = injector.get<DialogModal>('DialogModal');

    const TranslationHelper = injector.get<TranslationHelperClass>('TranslationHelper');
    const translationHelper = new TranslationHelper(modalConfig.localeNamespace);

    const modalElements = await modalConfig.elements();

    if (!modalElements.length) return;

    DialogModal.alert({
        title: translationHelper.get(modalConfig.titleLocaleKey, modalConfig.titleLocaleParams),
        content: getContent(modalConfig.localeNamespace, modalElements),
        classes: ['admissions-process-modal', ...modalConfig.modalClasses],
        scope: {
            ...modalConfig.callbacks,
        },
    });
}
