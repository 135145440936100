/*

# Overview

Our Rails API expects snake-cased parameters.  Since we prefer to use camel-casing in javascript, we want to configure
RTK-Query to convert between snake-casing and camel-casing.

# Scope

 * We convert parameter keys sent to the api either in query parameters or in the body of a post request from
    camel-casing snake-casing.
 * We convert the keys in a response from the api from snake-casing to camel-casing.  This is almost always the right thing
    to do, but there are some exceptions.  For example, see the example below labeled "Issue: uuids as object keys".  In that
    case we are able to deal with it my just using the camelcased keys, even though it's awkward.  If there were another situation
    where that seemed insufficient, I suppose we could change the keys again just for that particular endpoint in a transformResponse
    funtion.
 * When there is property named `fields[]` in the parameters, we treat it specially.  That property is generally used to tell our
    api which fields should be returned from a particular request.  The client should be able to refer to those fields in their
    camel-cased forms, but the server is going to be expecting them in their snake-cased form.  So, we convert the values in that array.

## FIXME

 * When sending keys to the server, we are not currently converting keys that end in [].  So, if we have
    a parameter like `'convertMe[]': ['some', 'array']`, it will not be converted to `convert_me` before
    going to the server like we would expect.  See comment in snakeCaseApiParams

## Issue: uuids as object keys

    One example of a place where globally camelizing all keys coming down from the server ends up being awkward is
    when we are using uuids as keys in an object.  For example, in `getAvailableUserManagementActions`

    the server returns something like:

    ```
    {
        "bc0e1b67-1152-5fc8-ae7e-277e569772a3": [],
        "d9b799b5-1987-5fb6-8bf1-b27f08e7f947": [...]
    }
    ```

    but the client ends up with something like:

    ```
    {
        "bc0E1B6711525Fc8Ae7E277E569772A3": [],
        "d9B799B519875Fb68Bf1B27F08E7F947": [...]
    }
        ```

    This is odd.  It would be better if we just kept the original keys here.  In this case, we deal with it by just
    camelcasing the ids when we need them to access the object, like this:

    ```
        availableUserManagementActions?.[camelCase(record.id)]
    ```

    It's not ideal, but it's not a big deal and it doesn't seem like enough to make us stop doing this conversion.

    Note that we're using the version of camelCase exported by this file. See comments near camelCase below for more explanation on that.

*/

import { snakeCase } from 'String';
import snakeCaseKeys from 'snakecase-keys';
import { type AnyObject } from '@Types';
import transformKeyCase from 'Utils/transformKeyCase';
import { type FetchArgs } from '@reduxjs/toolkit/query';
import { type BaseFetchQueryFn, type BaseFetchQueryFnArgs } from 'ReduxHelpers';

// In order to denote arrays in querystrings, we have params that end in `[]`
// If we used the default options in snakecase-keys, those closing brackets would be
// stripped out.
// In order to avoid this, we have to override the default value
// for stripRegexp.  The default value is /[^A-Z0-9]+/gi. We modify that
// by adding in `[]` to the list of characters that are not stripped out.
// Reading the code in snakeCaseKeys, it's a bit hard to trace how to set options,
// and how I determined what the default value was, so I'm going to trace it here:
//
// - snakecase-keys requires snake-case, and passes through the value of parsingOptions
//      as the second argument (see https://github.com/bendrucker/snakecase-keys/blob/master/index.js)
// - snake-case is part of the change-case package. All of the methods in that package accept options as documented at https://github.com/blakeembrey/change-case#options
// - snake-case delegates to noCase (see https://github.com/blakeembrey/change-case/blob/master/packages/snake-case/src/index.ts)
// - noCase uses /[^A-Z0-9]+/gi as the DEFAULT_STRIP_REGEXP (see https://github.com/blakeembrey/change-case/blob/040a079f007879cb0472ba4f7cc2e1d3185e90ba/packages/no-case/src/index.ts#L19)
const snakeCaseParsingOptions = { stripRegexp: /[^A-Z0-9[\]]+/gi };

export function snakeCaseApiParams(camelCasedParams: AnyObject): AnyObject {
    const snakeCased = snakeCaseKeys(camelCasedParams, {
        parsingOptions: snakeCaseParsingOptions,
    });
    if ('fields[]' in snakeCased) {
        snakeCased['fields[]'] = (snakeCased['fields[]'] as string[]).map(s => snakeCase(s, '_'));
    }

    return snakeCased;
}

function snakeCaseQueryBody(args: BaseFetchQueryFnArgs) {
    const fetchArgs = args as FetchArgs;
    if (fetchArgs.body) {
        fetchArgs.body = snakeCaseApiParams(fetchArgs.body);
    }

    return fetchArgs as BaseFetchQueryFnArgs;
}

type NextFn = (args: BaseFetchQueryFnArgs) => ReturnType<BaseFetchQueryFn<unknown>>;
export async function convertQueryCasing(args: BaseFetchQueryFnArgs, next: NextFn) {
    const result = await next(snakeCaseQueryBody(args));
    if (result.data) {
        // Before changing the next line of code, see the comment near `export function camelCase` above
        result.data = transformKeyCase(result.data as AnyObject, { to: 'camelCase' });
    }
    return result;
}
