import { type AnyObject } from '@Types';
import { type TodoItem } from 'AdmissionsGuidance/StudentDashboard.types';
import clsx from 'clsx';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { enrollmentSubTodoVisible } from './helpers';

export function EnrollmentTodoItem({ item, showCompletedTodos }: { item: TodoItem; showCompletedTodos: boolean }) {
    const { t } = useTranslation('back_royal');

    const inReview = useMemo(() => !!item.inReview?.(), [item]);
    const completed = useMemo(() => item.hasBeenCompleted(), [item]);
    const irrelevant = useMemo(() => !!item.irrelevant?.(), [item]);
    const disabled = useMemo(() => item.disabled(), [item]);
    const isLoading = useMemo(() => !!item.isLoading?.(), [item]);
    const hasSubTodos = !!item.subTodosConfig?.getSubTodos?.()?.length;

    const handleClick = useCallback(() => {
        if (completed) return;
        item.onClick();
    }, [item, completed]);

    return (
        <>
            <button
                className={clsx(
                    'todo group mx-0 my-[6px] flex h-auto items-center justify-start gap-[5px] border-0 bg-transparent px-0 py-[3px] shadow-none',
                    disabled
                        ? 'cursor-not-allowed'
                        : completed || irrelevant || inReview
                        ? 'cursor-default'
                        : 'cursor-pointer',
                )}
                type="button"
                name={item.name}
                onClick={handleClick}
            >
                <span
                    className={clsx(
                        'todo-icon width-[21px] ps-[10px] align-middle text-sm text-white',
                        completed || irrelevant || inReview || disabled ? 'text-opacity-50' : '',
                    )}
                >
                    <i
                        className={clsx(
                            'fa-regular',
                            completed ? 'fa-circle-check' : irrelevant || inReview ? 'fa-circle-minus' : 'fa-circle',
                        )}
                    />
                </span>
                {!isLoading && (
                    <span className="todo-text w-[calc(100%_-_25px)] text-left">
                        <span
                            className={clsx(
                                'text-sm font-semibold normal-case leading-[18px] ',
                                completed || irrelevant
                                    ? 'text-white text-opacity-50'
                                    : inReview || disabled
                                    ? 'text-yellow text-opacity-50'
                                    : 'text-yellow group-hover:underline',
                            )}
                        >
                            {t(`lessons.stream.student_dashboard_enrollment.${item.localeKey}`)}
                        </span>
                        {inReview && (
                            <span className="text-sm font-normal normal-case text-white">
                                &nbsp;{t('lessons.stream.student_dashboard_enrollment.in_review')}
                            </span>
                        )}
                    </span>
                )}
                {isLoading && <FrontRoyalSpinner className="m-0" color="white" />}
            </button>
            {hasSubTodos &&
                item.subTodosConfig!.getSubTodos().map((subTodo: AnyObject, i: number) =>
                    enrollmentSubTodoVisible(subTodo, item, showCompletedTodos) ? (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            className={clsx('sub-todo my-[3px] flex items-center justify-start gap-2 py-[3px]', {
                                'in-review': item.subTodosConfig!.inReview(subTodo),
                                completed: item.subTodosConfig!.hasBeenCompleted(subTodo),
                            })}
                        >
                            <span className="todo-icon todo-icon-placeholder inline-block w-[21px] ps-[10px] text-sm" />
                            <span
                                className={clsx(
                                    'todo-icon mt-[2px] inline-block w-[21px] ps-[10px] text-sm text-white',
                                    completed || irrelevant || inReview || disabled ? 'text-opacity-50' : '',
                                )}
                            >
                                <i
                                    className={clsx(
                                        'fa-regular',
                                        completed
                                            ? 'fa-square-check'
                                            : irrelevant || inReview
                                            ? 'fa-square-minus'
                                            : 'fa-square',
                                    )}
                                />
                            </span>
                            <span className="todo-text ">
                                <span
                                    className={clsx(
                                        'text-sm text-white',
                                        completed || irrelevant || inReview || disabled ? 'text-opacity-50' : '',
                                    )}
                                >
                                    {item.subTodosConfig!.getSubTodoText(subTodo)}
                                </span>
                                {item.subTodosConfig!.inReview(subTodo) && (
                                    <span className="text-sm normal-case text-white">
                                        &nbsp;{t('lessons.stream.student_dashboard_enrollment.in_review')}
                                    </span>
                                )}
                            </span>
                        </div>
                    ) : null,
                )}
        </>
    );
}
