import { Link, type LinkProps } from 'react-router-dom';
import { Icon } from 'FrontRoyalIcon';
import { cva, type VariantProps } from 'class-variance-authority';
import { useNavigationHelper } from 'FrontRoyalAngular';

const anchorLink = cva(null, {
    variants: {
        withArrow: {
            true: 'inline-flex gap-[0.35rem]',
        },
        color: {
            brand: 'text-coral hover:text-coral-dark valar:text-blue valar:hover:text-blue-dark',
            'lightish-blue': 'text-lightish-blue',
        },
    },
    defaultVariants: {
        withArrow: false,
        color: 'lightish-blue',
    },
    compoundVariants: [
        {
            color: 'lightish-blue',
            withArrow: false,
            className: 'hover:underline',
        },
        {
            withArrow: true,
            className: 'hover:no-underline',
        },
    ],
});

type AnchorLinkProps = LinkProps & VariantProps<typeof anchorLink>;

export function AnchorLink(props: AnchorLinkProps) {
    const { children, withArrow, color, className, target = '_blank', ...rest } = props;
    const { loadUrl } = useNavigationHelper();

    // If we're navigating to an absolute URL, we can safely assume that we aren't navigating the user to another
    // route within our app. In that case, the standard Link component does not work on iOS, so we use a standard DOM node with an onClick handler.
    if (typeof rest.to === 'string' && rest.to.startsWith('http')) {
        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid
            <a
                className={anchorLink({ withArrow, color, className })}
                onClick={() => loadUrl(rest.to as string, target)}
                role="link"
                tabIndex={0}
            >
                {children}
                {withArrow === true ? <Icon iconName="RightArrow16" className="rtl:rotate-180" /> : null}
            </a>
        );
    }

    return (
        <Link className={anchorLink({ withArrow, color, className })} target={target} {...rest}>
            {children}
            {withArrow === true ? <Icon iconName="RightArrow16" className="rtl:rotate-180" /> : null}
        </Link>
    );
}
