import cacheAngularTemplate from 'cacheAngularTemplate';
import { react2Angular } from 'FrontRoyalReact2Angular';
import { CertCongratsModal } from 'ExecEdEligibility/components/CertCongratsModal/CertCongratsModal';
import admissionsInfoBoxDesktopTemplate from '../views/admissions_info_box_desktop.html';
import admissionsInfoBoxMobileTemplate from '../views/admissions_info_box_mobile.html';

const angularModule = angular.module('AdmissionsGuidance', ['FrontRoyal.Users']);

cacheAngularTemplate(angularModule, 'AdmissionsGuidance/admissionsInfoBoxDesktop', admissionsInfoBoxDesktopTemplate);
cacheAngularTemplate(angularModule, 'AdmissionsGuidance/admissionsInfoBoxMobile', admissionsInfoBoxMobileTemplate);

angularModule.component(
    'certCongratsModal',
    react2Angular(
        CertCongratsModal,
        ['currentUser', 'filteredEligibilityBundle', 'programTypeConfigs', 'loadUrl'],
        '',
        false,
    ),
);

export default angularModule;
