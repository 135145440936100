import { useCallback, useMemo } from 'react';
import { useCurrentUser } from 'FrontRoyalAngular';
import { useTranslation } from 'react-i18next';
import useIsMobileMixin from 'StudentNetwork/react/useIsMobileMixin';
import { useUserIdVerificationViewModel } from 'FrontRoyalAngular/useUserIdVerificationViewModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard } from '@fortawesome/pro-regular-svg-icons/faIdCard';

export function IdVerificationBox() {
    return <IdVerificationBoxPropsWrapper />;
}

function IdVerificationBoxPropsWrapper() {
    const currentUser = useCurrentUser();
    const { xsOrSm } = useIsMobileMixin();
    const UserIdVerificationViewModel = useUserIdVerificationViewModel();

    const userIdVerificationViewModel = useMemo(() => new UserIdVerificationViewModel(), [UserIdVerificationViewModel]);

    const handleVerifyClick = useCallback(() => {
        userIdVerificationViewModel.launchVerificationModal();
    }, [userIdVerificationViewModel]);

    if (!currentUser) return null;

    return <IdVerificationBoxPresentation xsOrSm={xsOrSm} onVerifyClick={handleVerifyClick} />;
}

export function IdVerificationBoxPresentation({
    xsOrSm,
    onVerifyClick,
}: {
    xsOrSm: boolean;
    onVerifyClick: () => void;
}) {
    const { t } = useTranslation('back_royal');

    if (xsOrSm) {
        // MOBILE view
        return (
            <div data-testid="content-container" className="flex flex-col items-center justify-start p-5">
                <p className="mb-[30px] text-md font-normal text-white">
                    {t('lessons.stream.student_dashboard_identity_verification.in_order_to_continue')}
                </p>
                <button
                    className="mb-[10px] flex w-full items-center justify-center rounded-[50px] border border-solid border-white bg-transparent px-[30px] py-[10px] text-base font-medium text-white shadow-none"
                    type="button"
                    onClick={onVerifyClick}
                    data-testid="verify_id"
                >
                    {t('lessons.stream.student_dashboard_identity_verification.verify_identity')}
                </button>
            </div>
        );
    }

    return (
        // DESKTOP view
        <div className="bg-[linear-gradient(168deg,_#a52e3b_0%,_#600b14_96%)] px-5 pb-[30px] pt-[20px]">
            <div data-testid="content-container" className="flex flex-col items-center justify-start">
                <h2 className="mb-[20px] flex flex-col items-center justify-start">
                    <span className="mb-[5px] block text-xs font-normal uppercase text-white">
                        {t('lessons.stream.student_dashboard_identity_verification.identity')}
                    </span>
                    <span className="mb-[10px] block text-[28px] font-semibold uppercase text-white">
                        {t('lessons.stream.student_dashboard_identity_verification.verification')}
                    </span>
                    <span className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-yellow text-lg font-normal text-red-dark">
                        <FontAwesomeIcon icon={faIdCard} />
                    </span>
                </h2>
                <p className="mb-[18px] px-[22px] text-center text-md font-normal leading-[26px] text-white">
                    {t('lessons.stream.student_dashboard_identity_verification.in_order_to_continue')}
                </p>
                <button
                    className="m-0 flex h-auto items-center justify-center whitespace-nowrap rounded border-0 bg-transparent bg-yellow px-4 py-[10px] text-base font-normal normal-case leading-[1.33] text-red-dark shadow-none transition-[all_.3s_ease] hover:bg-[#ffcb33]"
                    data-testid="verify_id"
                    type="button"
                    onClick={onVerifyClick}
                >
                    {t('lessons.stream.student_dashboard_identity_verification.verify_identity')}
                </button>
            </div>
        </div>
    );
}
