import { forwardRef, type TextareaHTMLAttributes, type ForwardedRef } from 'react';

import clsx from 'clsx';
import { Controller } from 'FrontRoyalReactHookForm';

type Props = {
    name: string;
    className?: string;
    wrapperClassName?: string;
    defaultValue?: string;
    resizable?: boolean;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

/**
 * A textarea that automatically grows its height to match the text content.
 * Adapted from the solution described at https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
 * */

export const AutoGrowTextAreaField = forwardRef(
    (
        { className, wrapperClassName, name, defaultValue, resizable = false, ...rest }: Props,
        ref: ForwardedRef<HTMLTextAreaElement>,
    ) => (
        <Controller
            name={name}
            defaultValue={defaultValue}
            render={({ field }) => (
                <div data-testid="auto-grow-textarea-wrapper" className={clsx('grid', wrapperClassName)}>
                    <textarea
                        className={clsx('overflow-hidden', { 'resize-none': !resizable }, className)}
                        style={{ gridArea: '1 / 1 / 2 / 2' }}
                        rows={1}
                        {...field}
                        {...rest}
                        ref={ref}
                    />
                    <div
                        data-testid="replicated-content"
                        aria-hidden
                        className={clsx('invisible whitespace-pre-wrap', className)}
                        style={{ gridArea: '1 / 1 / 2 / 2' }}
                    >
                        {field.value}
                    </div>
                </div>
            )}
        />
    ),
);
